export default {
  //Welcome
  what_are_you_looking_for: "What type of <b>fragrance</b> are you looking for",
  gender_f: "FEMALE",
  gender_m: "MALE",
  gender_u: "GENDER NEUTRAL",
  //Mode select
  mode_select_title:
    "Help us understand the <b>products you love</b>, and get recommendations tailored to your preferences.",
  search_by: "Search by",
  mode_brands: "BRANDS",
  mode_ingredients: "INGREDIENTS",
  //Nav header
  start_new_session: "Start new session",
  select_ingredients: "Select Ingredients",
  select_fragrances: "Select Fragrances",
  your_recommendations: "Your recommendations",

  //Perfume search
  search_perfumes_placeholder: "Type your favorite fragrance or brand",
  your_selection: "Your selection",
  best_sellers: "Best sellers",
  select_fragrances_instructions:
    "Select at least <b>2 fragrances</b> to get recommendations tailored to your taste",
  get_recommendations: "GET RECOMMENDATIONS",
  search_m_fragrances: "Search for your favorite <b>male fragrances</b>",
  search_f_fragrances: "Search for your favorite <b>female fragrances</b>",
  search_u_fragrances:
    "Search for your favorite <b>gender neutral fragrances</b>",
  top_recos_perfumes:
    "<b>Top %s recommendations</b> - These recommendations are based on your olfactory profile.",
  top_recos_perfumes_mobile:
    "<b>Top %s recommendations</b><br>These recommendations are based on your olfactory profile.",
  no_perfumes_found: "No matches for this search.",
  //Ingredient search
  search_ingredients_placeholder: "Type your favorite ingredient",
  best_match_for_selection: "Best match for selection",
  top_recos_ingredients: "<b>Top %s</b> - based on your ingredient selection.",
  see_all_ings_in_this_fam: "See all ingredients in this family",

  // Donut
  your_olfactory_profile_families:
    "<b>Your olfactory profile</b> contains fragrances from the %s families.",
  your_olfactory_profile_subfamilies:
    "Your profile also has notes from the %s subfamilies.",

  // Recommendations
  buy_it_now: "SHOP NOW",
  show_now: "SHOP NOW",
  why_are_we_recommending_this_fragrances:
    "Why we are recommending these fragrances?",
  rest_of_recos: "OTHER RECOMMENDATIONS",
  your_favorite_ings: "Your Favorite Ingredients",
  something_went_wrong: "Something went wrong",
  see_more: "See more",
  hide_ingredients: "Hide ingredients",

  // Perfume
  shop_now: "SHOP NOW",
  from: "FROM",
  complete_ingredients_list: "Complete Ingredients List",
  why_do_we_recommend: "Why do we recommend <b>this fragrance?</b>",
  recommended_by_fam:
    "belongs to one of $possessive_plural favorite olfactive families, <b>%FAMILY%</b>",
  not_recommended_by_fam:
    "Even though it has a different profile than $possessive_singular_prep, $you_prep should try it, since",
  it: "it",
  also: "also",
  $pronoun_self: "you",
  $possessive_singular_self: "yours",
  $possessive_refl_singular_self: "my",
  $possessive_plural_self: "your",
  $possessive_refl_plural_self: "my",
  $possessive_singular_prep_self: "your",
  $possessive_singular_liste_self: "your",
  $you_self: "you",
  $you_prep_self: "you",
  $do_you_have_self: "you have",
  $that_you_love_self: "that you love",
  $you_love_self: "you love",
  $you_adore_self: "you love",
  $apreciate_self: "like",
  $you_have_self: "you have",
  and: "and",
  recommended_by_hero: "$you_adore its main ingredient: <b>%HERO%</b>",
  recommended_by_ings:
    "it has some of $possessive_plural favorite ingredients: <b>%INGS%</b>",
  furthermore: "Furthermore",
  other_ings_present: "As well as others, more subtly present",
  evaluate_this_reco: "Evaluate this recommendation",
  how_relevant_do_you: "How relevant do you find <b>this recommendation</b>?",
  back: "Back",
  submit: "Submit",
  send: "Send",
  facebook: "Facebook",
  twitter: "Tweeter",
  whatsapp: "Whatsapp",
  email: "E-mail",

  // How it works
  how_it_works: '"How it works"',
  how_it_works_p1:
    "The perfume industry is constantly growing, especially niche brands (exclusive and not as mainstream). With so many perfumes on offer, it can be hard to know where to start when choosing one. The Boots Fragrance Profiler was created with the aim of helping you find your signature scent, turning the journey of buying a perfume into a personal and fun experience. ",
  how_we_can_help_you: "How we can help you",
  how_it_works_p2:
    "When you create an account with Boots, you can configure your scent profile based on your favourite ingredients and fragrances. So sign up if you haven't already, because you're just a few steps away from finding your signature scent.",
  we_show_you_what_you_cant_see: "We show you what you can't see",
  how_it_works_p3:
    'We use a Quadrification that allows you to "see" a scent. This tool visually represents the scent of a fragrance so that you can see which ingredients it contains.',
  how_it_works_p4:
    "There are more ingredients in a perfume, but the quadrification focuses on the ones that define a fragrance's main character, the ones you would perceive if you were to train your sense of smell.",
  how_it_works_p5:
    "<b>Ingredients are not always the same</b>: the same ingredient can be of a different quality and in a different level of concentration in one perfume and in another. Quality and concentration are two aspects you won't be able to see.",
  how_it_works_p6:
    "In addition to the quadrification, we classify fragrances into families and subfamilies, according to their perfume style and character. This classification is represented by two concentric coloured circles, followed by the family and the subfamily.",
  we_recommend_best_fit: "We recommend the best fit for you",
  how_it_works_p7:
    "We analyse and evaluate the composition of thousands of perfumes to interpret their dominant ingredients and fragrance style.",
  how_it_works_p8:
    "The recommendations we make are based on this rigorous analysis of each perfume and on 100% perfumery and independent criteria.",
  how_it_works_p9:
    "Thanks to a complex perfume classification and the use of algorithms, we've built a unique search engine to offer you personalised recommendations based on your fragrance preferences. The perfumes we'll recommend will share common ingredients, similar or close perfume styles, although that doesn't mean they're the same.",

  // Other
  loading: "Loading",
  no_ingredients_found: "None ingredient matches the search term",
  no_ingredients_found_conditioned_to_previous_selection:
    "None ingredient matches the previous selected ingredients and the search term",
};

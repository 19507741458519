<template>
  <div v-click-outside="()=> open=false" class="share-component">
    <div class="share-button" @click="shareButtonClickHandler()" />
    <div v-if="open" class="share-panel">
      <div class="close-button" @click="open = false" />
      <social-sharing
        :url="url"
        :title="$t('share_title')"
        :description="$t('share_desc')"
        :quote="$t('share_quote')"
        hashtags="boots"
        :twitter-user="null"
        inline-template
        @open="open=false"
      >
        <div class="actions">
          <network network="facebook" class="action-button">
            <div class="icon facebook" />
            <div class="label">{{ $t('facebook') }}</div>
          </network>
          <network network="twitter" class="action-button">
            <div class="icon twitter" />
            <div class="label">{{ $t('twitter') }}</div>
          </network>
          <network network="whatsapp" class="action-button">
            <div class="icon whatsapp" />
            <div class="label">{{ $t('whatsapp') }}</div>
          </network>
          <network network="email" class="action-button">
            <div class="icon email" />
            <div class="label">{{ $t('email') }}</div>
          </network>
        </div>
      </social-sharing>
    </div>
  </div>
</template>

<script>
// import ButtonWithFeedback from '@/components/ButtonWithFeedback'
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
import ClickOutside from "vue-click-outside";
export default {
  name: "SharePanel",
  directives: {
    ClickOutside,
  },
  props: ["data"],
  data() {
    return {
      open: false,
    };
  },
  computed: {
    url() {
      return window.location.href.split("?")[0];
    },
  },
  methods: {
    shareButtonClickHandler() {
      this.open = !this.open;
      if (this.open) {
        this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
          type: "PERFUME_SHARED",
          data: { id: this.data.id },
        });
      }
    },
  },
};
</script>

<style scoped lang="stylus">
.share-component
  position: absolute
  top: 0
  right: 0
  user-select: none

  .share-button
    m-icon("share", 25)
    cursor: pointer

    @media screen and (min-device-width: 768px) and (max-device-width: 1024px)
      m-icon("share", 30)

    .mobile &
      m-icon("share", 25)

  .share-panel
    position: absolute
    top: "calc(100% + %s)" % vh(10px)
    right: 0
    z-index: 2
    display: flex
    padding-top: vh(25px)
    padding-right: vw(25px)
    padding-bottom: vh(15px)
    padding-left: vw(15px)
    width: auto
    border: 1px solid #ddd
    background-color: #fff
    box-shadow: 0 1px 5px 1px rgba(#000, 0.1)

    .mobile &
      padding-top: vw(25px)
      padding-bottom: vw(15px)

    >>> .actions
      display: grid
      width: 100%
      grid-template-columns: 1fr 1fr 1fr 1fr
      gap: vw(20px)

      .mobile &
        margin: 0

      .action-button
        display: flex
        flex-direction: column
        align-items: center

        .icon
          margin-bottom: vh(5px)
          border-radius: 50%
          background-color: $primary-theme-color

          &.facebook
            m-icon("facebook", 30)

          &.email
            m-icon("email", 30)

          &.whatsapp
            m-icon("whatsapp", 30)

          &.twitter
            m-icon("twitter", 30)

        .label
          m-font-size(10, 12)
          white-space: nowrap

    .close-button
      m-icon("close", 12)
      position: absolute
      top: vw(7.5px)
      right: vw(7.5px)
      cursor: pointer
</style>
import axios from "axios";
import store from "@/store";
// import Router from '../router';

let instance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
instance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    ...{
      lang: store.state.i18n.lang,
      hostId: config.noHost ? null : process.env.VUE_APP_HOST_ID,
    },
  };
  return config;
});
let instanceWithoutHostId = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
instanceWithoutHostId.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
    lang: store.state.i18n.lang,
  };
  return config;
});

let cache = {};

function addToCache(type, key, value) {
  if (!cache[type]) cache[type] = {};
  cache[type][key] = value;
  return value;
}
function getCached(type, key) {
  return cache[type] && cache[type][key];
}

/**
 * return perfumes by text. <br/>
 * Without HostID. <br/>
 *
 * @param {string} query
 * @param {'M' | 'F' | 'U'} gender
 * @returns {Promise<Perfume[]>}
 */
export function searchByName(query, gender) {
  // let path = `http://localhost:3001/api/search/?strategy=boots_profiler&q=${query}&included.gender=${gender
  let path = `/v7/search?q=${query}&included.gender=${gender
    .split("")
    .join(",")}`;
  return (
    instance
      // .get(/*process.env.VUE_APP_API_URL + */path)
      .get(process.env.VUE_APP_API_URL + path)
      .then((response) => {
        let res = response.data;
        return res;
      })
      .then((datas) => {
        if (datas.data) datas = datas.data;
        datas.forEach((perf) => {
          addToCache("perfume_puig_search", perf.id, perf);
        });
        return datas;
      })
  );
}
export function searchByIngredients(ingIds) {
  let gender = store.state.user.gender;
  let path = `/perfume_search/by_ingredients/`;
  return instance
    .post(
      process.env.VUE_APP_API_URL + path,
      ingIds.map((id) => ({ ingredientId: id, proportion: 10 })),
      {
        params: {
          gender: gender.split("").join(","),
        },
      }
    )
    .then((response) => {
      return response.data.slice(0, 16).map((d) => d.perfume);
    });
}

export function getAllIngredients() {
  let gender = store.state.user.gender;
  // let lang = store.getters.lang
  // let cached = store.getters.getCachedIngredients()
  // if (cached)
  //   return Promise.resolve(cached)
  return instance
    .get(process.env.VUE_APP_API_URL + `/ingredients/`, {
      params: {
        gender: gender.split("").join(","),
      },
    })
    .then((response) => {
      let res = response.data;
      res.forEach((ing) => {
        addToCache("ingredient", ing.id, ing);
      });
      return res;
    });
}
export function getRestrictedIngredients(restrictions, gender) {
  // let lang = store.getters.lang
  // let cached = store.getters.getCachedIngredients()
  // if (cached)
  //   return Promise.resolve(cached)
  return instance
    .get(
      process.env.VUE_APP_API_URL +
        `/ingredients/getPossibleCombinations?gender=${gender
          .split("")
          .join(",")}&ingredients=${restrictions.map((i) => i.id)}`
    )
    .then((response) => {
      let res = response.data.map((d) => d.ingredient);
      res.forEach((ing) => {
        addToCache("ingredient", ing.id, ing);
      });
      return res;
    });
}
/**
 * Fetch by id WITH host ID <br/>
 * Fetch perfume from Product feed
 * @param {number} id
 * @returns {Promise<Perfume>}
 */
export function getPerfume(id) {
  let cached = getCached("perfume", id);
  if (cached) return cached;

  return addToCache(
    "perfume",
    id,
    instance
      .get(process.env.VUE_APP_API_URL + `/perfumes/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        return instance
          .get(process.env.VUE_APP_API_URL + `/perfumes/${id}`, {
            noHost: true,
          })
          .then((response) => {
            return response.data;
          });
      })
  );
}

export function getIngredient(id) {
  let cached = getCached("ingredient", id);
  if (cached) return cached;
  return addToCache(
    "ingredient",
    id,
    instance
      .get(process.env.VUE_APP_API_URL + `/ingredients/${id}`)
      .then((response) => {
        return response.data;
      })
  );
}

export function getRecommendations(mode, restrict) {
  let prom = getRecommendationsShallow(mode, restrict).then((datas) => {
    datas.forEach((perf) => {
      addToCache("perfume", perf.id, perf);
    });
    return datas;
  });
  store.dispatch("setLatestRecos", prom);
  return prom;
}
export function getRecommendationsShallow(mode, restrict) {
  let perfumeIds = store.state.profile.perfumes.map((p) => p.id);
  let ingredientIds = store.state.profile.ingredients;
  if (mode === "ingredients")
    return getRecommendationsByIngredients(ingredientIds);
  else return getRecommendationsByPerfumes(restrict || perfumeIds);
}

function getRecommendationsByIngredients(ingredientIds) {
  return searchByIngredients(ingredientIds);
}
function getRecommendationsByPerfumes(perfumeIds) {
  let gender = store.state.user.gender;

  if (perfumeIds.length === 0)
    return new Promise((resolve) => {
      resolve({ data: [] });
    });
  // let novelty = false;
  // let sure_bet = false;
  // let url = `http://localhost:3001/api/reco?perfumes=${perfumeIds}&limit=16&included.gender=${gender.split("").join(",")}&strategy=boots_profiler`
  let url =
    process.env.VUE_APP_API_URL +
    `/v7/reco?perfumes=${perfumeIds}&limit=16&light=true&included.gender=${gender
      .split("")
      .join(",")}`;
  // if(gender && gender !== 'U') url = url + `&gender=` + gender +',U'
  let prom = instance
    .get(url)
    .then((res) => getPerfumeByIds(res.data.data.map((perfume) => perfume.id)));

  return prom;
}
function getPerfumeByIds(ids) {
  return instance
    .get(
      process.env.VUE_APP_API_URL +
        `/perfumes/by_ids?sortByEntryOrder=true&allPresent=false&perfumeIds=${ids.join(
          ","
        )}`
    )
    .then((response) => {
      return response.data;
    });
}

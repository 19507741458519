<template>
  <div class="mode-select view">
    <div class="background" />
    <div class="step">
      <h1 class="title" v-html="$t('mode_select_title')" />
      <h2 class="description">{{ $t('search_by') }}:</h2>
      <div class="buttons">
        <router-link class="btn btn--primary" to="/perfume-search">{{ $t('mode_brands') }}</router-link>
        <router-link class="btn btn--primary" to="/ingredient-search">{{ $t('mode_ingredients') }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="stylus">
// Usa los estilos de Welcome.vue
</style>
<template>
  <div class="profile-edit-item" :class="typeClass" @click="$emit('click')">
    <div class="image" :style="{backgroundImage: `url(${bestImage || data.imageUrl || data.lightImageUrl})`}" />
    <div class="info">
      <div class="name">{{ type==="concept"?$t(data.name):data.name }}</div>
      <div v-if="data.brand" class="brand">{{ data.brand.name }}</div>
      <div class="type">{{ data.type }}</div>
      <families-with-icon v-if="type==='perfume'" :family="data.family" :subfamily="data.secondaryFamily" />
    </div>
    <div class="trash-button" @click="_onTrash" />
  </div>
</template>

<script>
import FamiliesWithIcon from "./Legacy/FamiliesWithIcon.vue";
import { capitalize } from "@/utils/textUtils";
export default {
  components: { FamiliesWithIcon },
  props: ["data", "type"],
  computed: {
    typeClass() {
      return this.type;
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = Object.values(this.data.eanData).find((d) => d.isMaster);
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
  },
  methods: {
    _onTrash() {
      if (!this.$listeners.onTrash) {
        let action = "remove" + capitalize(this.type);
        this.$store.dispatch(action, this.data);
      } else {
        this.$emit("onTrash");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.profile-edit-item
  display: flex
  align-items: center
  padding: vw(15px)
  border: 1px solid rgba(#000, 0.8)
  border-radius: 4px
  background-color: #fff
  box-shadow: 0 1px 0 #E6E6E6

  &:not(:last-child)
    margin-bottom: vh(20px)

    .mobile &
      margin-bottom: vw(15px)

  .mobile &
    padding: vw(10px)

    .image
      flex: 0 0 vw(60px)
      width: vw(60px)
      height: vw(60px)

  &.ingredient
    .image
      border-radius: 100%

  &.concept
    height: vw(80px)

    .image
      display: none

    .name
      m-font-size(20, 20)
      width: 100%
      text-align: center

  .image
    flex: 0 0 vw(70px)
    margin-right: vw(10px)
    width: vw(70px)
    height: vw(70px)
    background-position: center
    background-size: contain
    background-repeat: no-repeat

  .info
    flex: 1 1 0%

    .name
      m-font-size(12, 14)
      margin-bottom: vh(2px)
      font-weight: 700

    .brand
      m-font-size(12, 16)
      margin-bottom: vh(5px)

  .trash-button
    m-icon("bin", 32)
    cursor: pointer
</style>
<template>
  <div class="welcome view">
    <div class="background-video-container">
      <video
        class="background-video"
        autoplay
        muted
        loop
        playsinline
        :src="videoUrl"
        @canplay="onCanPlay"
      >
        <source :src="videoUrl" type="video/mp4">
      </video>
    </div>
    <loading v-if="loading" />
    <div v-if="!loading" class="step">
      <h1 class="title" v-html="$t('what_are_you_looking_for')" />
      <div class="buttons">
        <div class="btn btn--primary" @click="()=>setGender('FU')">{{ $t('gender_f') }}</div>
        <div class="btn btn--primary" @click="()=>setGender('MU')">{{ $t('gender_m') }}</div>
        <div class="btn btn--primary" @click="()=>setGender('U')">{{ $t('gender_u') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    videoUrl() {
      return `${process.env.BASE_URL}perfumes.mp4`;
    },
  },
  methods: {
    onCanPlay() {
      this.loading = false;
    },
    setGender(g) {
      this.$store.dispatch("setGender", g);
      this.$router.push("/mode-select");
    },
  },
};
</script>

<style lang="stylus">
.welcome,
.mode-select
  width: 100%
  height: 100%

  .background
    width: 100%
    height: 100%
    background-color: #fff

  .step
    position: absolute
    top: 50%
    left: 50%
    display: flex
    flex-direction: column
    align-items: center
    padding: vh(100px) vw(75px)
    max-width: vw(800px)
    width: 70%
    background-color: rgba(#fff, 0.95)
    text-align: center
    transform: translate(-50%, -50%)

    .mobile &
      padding: vw(20px)
      width: 90%

  .title
    m-font-size(28, 44)
    margin-top: 0
    margin-bottom: vh(40px)
    max-width: 50ch
    color: $primary-theme-color
    font-weight: 300

    .mobile &
      m-font-size(17, 30)
      margin-bottom: vw(30px)
      max-width: 70%

  .description
    m-font-size(25, 30)
    margin-top: vh(-20px)
    margin-bottom: vh(20px)
    color: $primary-theme-color
    font-weight: 300

    .mobile &
      m-font-size(15, 18)
      margin-bottom: vw(20px)

  .buttons
    display: flex
    align-items: center

    .mobile &
      flex-direction: column
      width: 100%
      gap: vw(15px)

    .btn
      m-font-size(14, 17)
      padding: vw(10px) vw(20px)
      height: vw(40px)

      &:nth-child(1),
      &:nth-child(2)
        min-width: vw(128px)

      &:nth-child(3)
        min-width: vw(185px)

      .mobile &
        margin-left: 0
        width: 100%
        height: auto

.mode-select
  .title
    .mobile &
      max-width: 90%

  .btn
    &:nth-child(1),
    &:nth-child(2)
      min-width: vw(150px)

.background-video-container
  position: fixed
  top: 50%
  left: 50%
  overflow: hidden
  width: 1440px
  height: 800px
  transform: translate(-50%, -50%)

.background-video
  position: absolute
  top: 0
  left: 5px
  width: 100%
  // height: 100%
</style>
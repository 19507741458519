<template>
  <transition name="fade">
    <div v-if="active" class="loading-overlay">
      <div class="content">
        <div class="icon" />
        <div v-if="text" class="text">{{ $t(text) }}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "LoadingOverlay",
  props: {
    text: {
      type: String,
      default: "loading",
    },
  },
  data() {
    return {
      active: false,
    };
  },
  mounted() {
    this.$nextTick(() => (this.active = true));
  },
};
</script>

<style scoped lang="stylus">
$radius = vw(60px)
$radius1 = "calc( %s - 1px)" % vw(60px)
$outer-diameter = vw(120px)
$inner-diameter = vw(100px)

.loading-overlay
  position: absolute
  top: $header-height
  left: 0
  z-index: 10
  display: flex
  justify-content: center
  max-height: 100vh
  width: 100%
  height: 100%
  background-color: rgba(#fff, 0.9)

  .mobile &
    top: $header-height-mobile

  &.fade-enter-active
    transition: opacity 0.3s

  &.fade-leave-active
    transition: opacity 0.3s
    transition-delay: 0.3s

  &.fade-enter,
  &.fade-leave-to
    opacity: 0

  .content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

    .icon
      width: vw(40px)
      height: vw(40px)
      background-image: url("~@/assets/images/boots_loader_spinner.gif")
      background-position: center center
      background-size: contain
      background-repeat: no-repeat

    .text
      m-first-letter-case()
      m-font-size(16, 20)
      margin: vw(5px)
      text-align: center
      font-weight: bold
</style>
<template>
  <div class="view recommendations" :class="{'scrollable': !$asyncComputed.recommendations.updating}">
    <Profile v-if="showProfile" />
    <div class="top">
      <toggle-profile v-if="isMobile && !showProfile && mode !== 'ingredients'" />
      <template v-if="$asyncComputed.recommendations.exception">
        <div class="no-data-disclaimer">
          <div class="no-data-content">{{ $t('something_went_wrong') }}</div>
        </div>
      </template>
      <template v-else-if="isMobile">
        <div v-if="topRecos && topRecos.length > 0" class="description">
          <div class="title" v-html="$t(`top_recos_${this.$store.state.profile.mode}`).replace('%s', topRecos.length)" />
        </div>
        <carousel
          :scroll-per-page="false"
          :per-page="1"
          :pagination-enabled="false"
          :navigation-enabled="true"
        >
          <slide v-for="perfume in topRecos" :key="perfume.id">
            <perfume-card
              :data="perfume"
              :is-top-reco="true"
              @click="()=>onPerfumeClick(perfume)"
            />
          </slide>
        </carousel>
      </template>
      <template v-else>
        <div v-if="topRecos && topRecos.length > 0" class="description">
          <div class="title" v-html="$t(`top_recos_${this.$store.state.profile.mode}`).replace('%s', topRecos.length)" />
        </div>
        <div class="top-reco-list">
          <perfume-card
            v-for="perfume in topRecos"
            :key="perfume.id"
            :is-top-reco="true"
            :data="perfume"
            @click="()=>onPerfumeClick(perfume)"
          />
        </div>
      </template>
    </div>
    <div v-if="otherRecos && otherRecos.length > 0" class="bottom open">
      <div key="list" class="bottom-content">
        <div class="description">
          <div class="title">{{ $t('rest_of_recos') }}</div>
        </div>
        <div class="bottom-reco-list">
          <perfume-card
            v-for="perfume in otherRecos"
            :key="perfume.id"
            :data="perfume"
            @click="()=>onPerfumeClick(perfume)"
          />
        </div>
      </div>
    </div>
    <loading v-if="$asyncComputed.recommendations.updating" />
  </div>
</template>

<script>
import { getRecommendations } from "@/services/api";
import PerfumeCard from "../components/PerfumeCard.vue";
import Loading from "../components/Loading.vue";
// import { getEanData } from "@/utils/perfume";
import Profile from "../components/Profile.vue";
import ToggleProfile from "../components/ToggleProfile.vue";
import { Carousel, Slide } from "vue-carousel";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";

export default {
  components: {
    PerfumeCard,
    Loading,
    Profile,
    ToggleProfile,
    Carousel,
    Slide,
  },
  props: ["mode"],
  data() {
    return {
      needsRefresh: true,
    };
  },
  computed: {
    availableRecos() {
      return (
        this.recommendations &&
        this.recommendations.filter(({ eanData }) =>
          Object.values(eanData).some(
            ({ availability }) => availability && availability > 0
          )
        )
      );
    },
    topRecos() {
      return this.availableRecos && this.availableRecos.slice(0, 4);
    },
    otherRecos() {
      return this.availableRecos && this.availableRecos.slice(4);
    },
    selection() {
      let restricted = this.$route.query.restricted;
      if (this.mode === "ingredients") return this.profileIngredients || [];
      else {
        let perfs = (this.profilePerfumes || []).filter((p) => {
          return restricted ? restricted.indexOf(p.id) >= 0 : true;
        });
        return perfs;
      }
    },
    selectionString() {
      let list = this.selection.map((p) => p.name);
      if (list.length > 3) {
        return list.slice(0, 4).join(", ") + "...";
      } else if (list.length > 1) {
        return (
          list.slice(0, -1).join(", ") +
          ` ${this.$t("and")} ` +
          list[list.length - 1]
        );
      } else return list[0];
    },
    showProfile() {
      return (
        this.$route.query.showProfile === "true" &&
        this.$store.state.profile.mode !== "ingredients"
      );
    },
  },
  watch: {
    profilePerfumes() {
      if (this.profilePerfumes) {
        let restricted = this.$route.query.restricted
          ? this.$route.query.restricted.split(",")
          : null;
        this.updateRecos(restricted);
      }
    },
  },
  mounted() {
    let restricted = this.$route.query.restricted
      ? this.$route.query.restricted.split(",")
      : null;
    this.profilePerfumes && this.updateRecos(restricted);
  },
  asyncComputed: {
    profileIngredients() {
      return this.$store.getters.ingredients();
    },
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
    recommendations() {
      return this.$store.state.recos.latestRecos;
    },
  },
  methods: {
    updateRecos(restricted) {
      this.$store.state.recos.needsUpdate &&
        getRecommendations(this.mode, restricted).then((recos) => {
          const topResults = recos.map((perfume) => perfume.id).slice(0, 10);
          if (this.mode === "ingredients") {
            this.eventTracker.emitEvent(EVENT_NAME_ENUM.SEARCH_PERFUMES, {
              type: "INGREDIENT",
              data: {
                ingredients:
                  restricted || this.profileIngredients?.map(({ id }) => id),
              },
              topResults,
            });
          } else {
            const perfumes = this.profilePerfumes.map((perfume) => perfume.id);
            this.eventTracker.emitEvent(EVENT_NAME_ENUM.RECOMMENDATION_FETCH, {
              resultingProfile: {
                perfumes: perfumes,
              },
              topResults,
            });
          }
        });
    },
    onPerfumeClick(perfume) {
      this.$router.push({
        path: `/perfume/${perfume.id}`,
        query: { fromReco: true },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
// .VueCarousel
// width: 100%

// >>> .VueCarousel-navigation-prev,
// >>> .VueCarousel-navigation-next
// m-font-size(0)
// margin: 0 !important
// padding: 0 !important

// >>> .VueCarousel-navigation-prev
// m-icon("arrow-left-carousel", 32)
// transform: translateX(-60%)

// >>> .VueCarousel-navigation-next
// m-icon("arrow-right-carousel", 32)
// transform: translateX(60%)
.recommendations
  display: flex
  flex-direction: column
  padding-bottom: 0 !important

  .loading-overlay
    top: 0

  .perfume-card
    $width = percentage((1 / 4))
    $gap = vw(20px)
    flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
    margin: $gap
    border: 1px solid #A7A5A5
    border-radius: 15px 15px 15px 0

    .mobile &
      $width = percentage((1 / 2))
      $gap = vw(20px)
      flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
      margin: $gap

    >>> .image
      .mobile &
        margin-top: vw(20px)
        margin-bottom: vw(20px)
        width: 100%
        height: vw(180px)

    >>> .info
      .mobile &
        padding-right: vw(10px)
        padding-left: vw(10px)
        width: 100%

    >>> .btn
      margin-bottom: vh(20px)
      margin-left: vw(15px)

  .top
    flex: 1 1 0%
    margin-right: auto
    margin-left: auto
    padding: vh(50px) 0
    max-width: 1440px
    width: 100%
    background-color: #fff

    .mobile &
      padding: vw(25px)
      max-width: 100%
      background-color: rgba(#A7A5A5, 0.1)

    >>> .VueCarousel-navigation-prev
      transform: translate(-50%, -50%)

    >>> .VueCarousel-navigation-next
      transform: translate(50%, -50%)

    .toggle-profile
      .mobile &
        + .description
          margin-top: vw(35px)

    .description
      margin-bottom: vh(50px)
      text-align: center

      .mobile &
        margin-bottom: vw(20px)

      .title
        m-font-size(22, 26)
        color: $primary-theme-color

        .mobile &
          m-font-size(17, 20)

    .profile-link
      m-font-size(14, 18)
      margin-bottom: vw(20px)
      text-align: center
      text-decoration: underline
      cursor: pointer

    .top-reco-list
      position: relative
      display: flex
      flex-wrap: wrap
      justify-content: center

      .tablet &
        width: 100%

      .mobile &
        width: 100%

    .no-data-disclaimer
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)

      .mobile &
        width: 90%

      .no-data-content
        display: flex
        flex-direction: column
        align-items: center
        text-align: center

        .main
          m-font-size(24)
          margin: vw(20px)

        .sub
          m-font-size(20)
          color: $manatee

        .actions
          display: flex
          flex-wrap: wrap
          justify-content: center
          align-items: center
          margin: vw(15px)

          .button
            margin: vw(15px)
            width: vw(180px)

    .back-button
      position: fixed
      top: "calc(%s + %s)" % ($header-height vw(32px))
      left: vw(25px)
      z-index: 1
      display: flex
      align-items: center
      background-color: rgba(#fff, 0.9)
      box-shadow: 0 0 0 10px rgba(#fff, 0.9)
      cursor: pointer

      .mobile &
        top: "calc(%s + %s)" % ($header-height-mobile vw(20px))
        left: vw(15px)

      .back-icon
        m-icon("arrow-left", 14)
        margin-right: vw(5px)

      .back-text
        font-weight: $fw-medium
        m-font-size(14, 20)
        margin-top: 0.1em
        text-transform: uppercase

  .bottom
    background-color: #fff

    &.open
      .bottom-content
        flex-direction: column

    .perfume-card
      .mobile &
        $width = percentage((1 / 2))
        $gap = vw(10px)
        flex: 0 0 "calc(%s - (%s * 2))" % ($width $gap)
        margin: $gap

    .description
      margin-bottom: vh(25px)

      .mobile &
        margin-bottom: vw(20px)

    .title
      m-font-size(22, 26)
      color: $primary-theme-color
      font-weight: 700

      .mobile &
        m-font-size(17, 20)

    .bottom-content
      display: flex
      justify-content: center
      align-items: center
      margin-right: auto
      margin-left: auto
      padding: vw(30px)
      padding-top: 0
      max-width: 1440px
      width: 100%

      .mobile &
        padding: vw(40px) vw(10px) vw(30px)

      &.fade-enter-active
        transition: opacity 0.3s

      &.fade-leave-active
        transition: opacity 0.3s

      &.fade-enter,
      &.fade-leave-to
        opacity: 0

    .bottom-reco-list
      display: flex
      flex-wrap: wrap
      justify-content: center
      margin: auto
      width: 80%

      .tablet &
        width: 100%

      .mobile &
        margin-right: vw(0)
        margin-left: vw(0)
        width: 100%

      .perfume-card
        border: none
        border-radius: 0

        >>> .image
          flex: 0 0 vw(140px)
          margin-top: vh(30px)
          width: vw(140px)
          height: vw(140px)

        >>> .info
          .name
            height: vw(32px)

          .name,
          .brand
            m-font-size(12, 16)

.VueCarousel-navigation-prev
  m-icon("prev", 20) !important

.VueCarousel-navigation-next
  m-icon("next", 20) !important

.Vue,
.prev-label
  width: vw(20px)
  height: vw(20px)
  background-color: red
  cursor: pointer
</style>
<template>
  <div class="search-header-container" :class="{ 'first-search': firstSearch && !isIngredientsMode }">
    <div v-if="firstSearch" class="search-header-title" v-html="title" />
    <div class="search-header">
      <div class="search-icon" />
      <input
        ref="input"
        v-model="internalValue"
        :placeholder="placeholder"
        @keyup="onKeyUp"
      >
      <div v-if="internalValue" class="close-icon" @click="resetHandler" />
    </div>
  </div>
</template>
<script>
import debounce from "debounce";
export default {
  model: {
    prop: "value",
    event: "change",
  },
  props: ["value", "placeholder"],
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    firstSearch() {
      return this.$store.state.user.firstSearch;
    },
    isIngredientsMode() {
      return this.$store.state.profile.mode === "ingredients";
    },
    title() {
      switch (this.$store.state.user.gender) {
        case "MU":
          return this.$t("search_m_fragrances");
        case "FU":
          return this.$t("search_f_fragrances");
        default:
          return this.$t("search_u_fragrances");
      }
    },
  },
  watch: {
    internalValue() {
      this.debounceInput();
    },
    value() {
      if (this.value !== this.internalValue) this.internalValue = this.value;
    },
  },
  methods: {
    emitChange() {
      if (this.firstSearch) {
        this.$store.dispatch("setFirstSearch", false);
      }
      this.$emit("change", this.internalValue);
    },
    debounceInput: debounce(function () {
      this.emitChange();
    }, 500),
    onKeyUp(e) {
      if (e.keyCode === 13) this.$refs.input.blur();
    },
    resetHandler() {
      this.internalValue = "";
    },
  },
};
</script>
<style lang="stylus" scoped>
$search-header-height = 55

.search-header-container
  &:not(.first-search)
    .desktop &
      .search-header
        padding-left: vw(20px)

  &.first-search
    position: absolute
    top: 50%
    left: 50%
    z-index: 2
    transform: translate(-50%, -50%)

    .mobile &
      margin-top: -5%
      width: 90%

    .search-header
      overflow: hidden
      margin-left: -10%
      width: 120%
      border: 1px solid #A7A5A5
      border-radius: 5px

      .mobile &
        margin-left: 0
        width: 100%

.search-header
  display: flex
  align-items: center
  width: 100%
  height: vw($search-header-height)
  border-bottom: 1px solid #A7A5A5

  .mobile &
    height: vw(40px)

  input
    // m-font-size(16, 20)
    flex: 1 1 0%
    padding: 0
    height: 100%
    outline: none
    border: none
    text-transform: uppercase
    font-weight: 300

    &::placeholder
      // m-font-size(16, 20)
      text-transform: initial
      font-weight: 300

.search-header-title
  m-font-size(30, 46)
  m-letter-spacing(10)
  margin-bottom: vh(40px)
  color: $primary-theme-color
  text-align: center
  font-weight: 300

  .mobile &
    m-font-size(17, 32)
    margin-right: auto
    margin-left: auto
    max-width: vw(200px)

.search-icon
  m-icon("search-fill", $search-header-height)
  background-size: 100%

  .mobile &
    m-icon("search-fill", 40)

.close-icon
  m-icon("cross-black", 20)
  margin-right: vw(20px)
  margin-left: vw(20px)
  cursor: pointer

  .mobile &
    m-icon("cross-black", 15)
    margin-right: vw(10px)
    margin-left: vw(20px)
</style>
<template>
  <div class="nav-header">
    <nav class="nav-header__links">
      <a class="nav-header__link" @click.prevent="restart">
        <div class="nav-header__link__text">{{ $t('start_new_session') }}</div>
      </a>
      <div class="arrow-right" />
      <a
        class="nav-header__link"
        :class="{'nav-header__link--active': isActive('search')}"
        @click.prevent="goToSelect"
      >
        <div class="nav-header__link__text">{{ selectStepLabel }}</div>
      </a>
      <div class="arrow-right" />
      <a
        class="nav-header__link"
        :class="{
          'nav-header__link--disabled': isRecosDisabled,
          'nav-header__link--active': isActive('recos')
        }"
        @click.prevent="goToRecos"
      >
        <div class="nav-header__link__text">{{ $t('your_recommendations') }}</div>
      </a>
    </nav>
    <div class="nav-header__info">
      <toggle-profile v-if="!isMobile && isRecosRoute && $store.state.profile.mode !== 'ingredients'" />
      <div class="info-icon" @click="infoToggle" />
    </div>
    <how-it-works v-if="showInfo" @close="infoToggle" />
  </div>
</template>
<script>
import { ROUTE_NAMES } from "../router";
import ToggleProfile from "./ToggleProfile.vue";
import HowItWorks from "../views/HowItWorks.vue";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
export default {
  components: { ToggleProfile, HowItWorks },
  data() {
    return {
      showInfo: false,
    };
  },
  computed: {
    selectStepRoute() {
      if (this.$store.state.profile.mode === "ingredients") {
        return ROUTE_NAMES.INGREDIENT_SEARCH;
      } else {
        return ROUTE_NAMES.PERFUME_SEARCH;
      }
    },
    selectStepLabel() {
      if (this.selectStepRoute === ROUTE_NAMES.INGREDIENT_SEARCH) {
        return this.$t("select_ingredients");
      } else {
        return this.$t("select_fragrances");
      }
    },
    isRecosDisabled() {
      if (this.$store.state.profile.mode === "perfumes") {
        return this.$store.state.profile.perfumes.length < 3;
      } else {
        return this.$store.state.profile.ingredients.length < 1;
      }
    },
    isRecosRoute() {
      return this.$route.name === ROUTE_NAMES.RECOMMENDATIONS;
    },
  },
  methods: {
    restart() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.NEW_SESSION);
      this.$store.dispatch("reset");
      this.$router.push({ name: ROUTE_NAMES.WELCOME });
    },
    infoToggle() {
      this.showInfo = !this.showInfo;
    },
    goToSelect() {
      this.$router.push({ name: this.selectStepRoute });
    },
    goToRecos() {
      const mode = this.$store.state.profile.mode;
      this.$router.push(
        `/recommendations/${mode}${
          mode === "perfumes" && !this.isMobile ? "?showProfile=true" : ""
        }`
      );
    },
    isActive(linkId) {
      if (linkId === "recos") {
        return this.$route.name === ROUTE_NAMES.RECOMMENDATIONS;
      }
      if (linkId === "search") {
        return (
          this.$route.name === ROUTE_NAMES.PERFUME_SEARCH ||
          this.$route.name === ROUTE_NAMES.INGREDIENT_SEARCH
        );
      }
      return false;
    },
  },
};
</script>
<style lang="stylus">
.nav-header
  display: flex
  align-items: center
  padding: vh(6px) vw(40px)
  padding-right: vw(15px)
  height: $header-height
  border-bottom: 1px solid rgba(#A7A5A5, 0.3)

  .mobile &
    padding: vw(5px) vw(10px)
    height: vw(40px)
    background-color: #F2F2F2

.nav-header__links
  display: flex
  align-items: center
  height: 100%
  gap: vw(10px)

  .mobile &
    overflow: hidden
    gap: vw(2px)

.nav-header__link
  display: flex
  align-items: center
  min-width: 0
  height: 100%
  color: $primary-theme-color
  text-align: center

  &:not(.nav-header__link--active)
    .nav-header__link__text
      .mobile &
        m-ellipsis(100%)

.nav-header__link__text
  m-font-size(14, 17)
  m-letter-spacing(80)
  font-weight: 400

  .mobile &
    m-font-size(11, 13)
    white-space: nowrap

.nav-header__link--active
  padding: vw(10px) vw(20px)
  border-radius: 2px
  background-color: $secondary-theme-color
  color: #fff
  cursor: default

  .mobile &
    flex-shrink: 0
    padding: 0 vw(10px)

  &:hover
    background-color: $secondary-theme-color
    color: #fff
    cursor: default

.nav-header__link--disabled
  opacity: 0.5
  pointer-events: none

.nav-header__info
  display: flex
  align-items: center
  margin-left: auto
  gap: vw(20px)

.arrow-right
  flex-shrink: 0
  m-icon("arrow", 24)

  .mobile &
    m-icon("arrow", 12)

.info-icon
  m-icon("info", 32)
  margin-right: vw(-2px)
  margin-left: vw(10px)
  cursor: pointer

  .mobile &
    m-icon("info", 20)
</style>
// import {getRecommendations} from '@/services/api'
export default {
  state: {
    latestRecos: [],
    needsUpdate: true,
    shouldShowNotification: false,
    beenSeen: false
  },
  mutations: {
    setNeedsNewRecos(state,value) {
      state.needsUpdate = value
    },
    setLatestRecos(state, value) {
      state.latestRecos = value
    },
    setShouldShow(state, value) {
      if(state.beenSeen) state.shouldShowNotification = value
    },
    setBeenSeen(state, value) {
      state.beenSeen = value
    },
  },
  actions: {
    setNeedsNewRecos ({commit}) {
      commit('setNeedsNewRecos',true) 
      commit('setShouldShow',true)
    },
    setLatestRecos ({commit}, recos) {
      commit('setLatestRecos',recos) 
      commit('setNeedsNewRecos',false)
    },
    setNoNotification ({commit}) {
      commit('setShouldShow',false)
    },
    setBeenSeen ({commit}) {
      commit('setBeenSeen',true)
    }
    
  },
  getters: {
    
  }
}

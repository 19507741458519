<template>
  <div class="recommendation-profile">
    <div class="icon-close" @click="close" />
    <div class="recommendation-profile-inner">
      <profile-families :data="donutData" />
      <div class="donut-container">
        <donut v-if="donutData" :data="donutData" />
      </div>
      <div v-if="profileIngredients" class="ingredients-container">
        <div class="title"><b>{{ $t('your_favorite_ings') }}</b> ({{ profileIngredients.length }})</div>
        <div class="ingredients-list">
          <ingredient-item
            v-for="ing in displayIngredients"
            :key="ing.id"
            :data="ing"
            :selectable="false"
          />
        </div>
        <div class="see-more-btn" @click="displayAll = !displayAll">{{ displayAll ? $t('hide_ingredients') : $t('see_more') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Donut from "./Legacy/Donut.vue";
import ProfileFamilies from "./ProfileFamilies.vue";
import IngredientItem from "./Legacy/IngredientItem.vue";
export default {
  name: "Profile",
  components: { Donut, IngredientItem, ProfileFamilies },
  data() {
    return {
      displayAll: false,
    };
  },
  asyncComputed: {
    async profileIngredients() {
      return await this.$store.getters.profileIngredients();
    },
    donutData() {
      if (this.$store.state.profile.mode === "ingredients")
        return this.$store.getters.familiesWithSubsByIngredients();
      else return this.$store.getters.familiesWithSubsByPerfumes();
    },
  },
  computed: {
    displayIngredients() {
      return this.displayAll
        ? this.profileIngredients
        : this.profileIngredients.slice(0, 6);
    },
  },
  methods: {
    close() {
      this.$router.replace({
        query: { showProfile: false },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.recommendation-profile
  background-color: rgba(#A7A5A5, 0.1)

  .mobile &
    background-color: #fff

.recommendation-profile-inner
  position: relative
  display: flex
  align-items: center
  margin-right: auto
  margin-left: auto
  max-width: 1440px

  .mobile &
    display: block
    padding: vw(20px)
    max-width: 100%

  .profile-families
    flex: 1 1 0%
    padding: vw(30px)

    .mobile &
      margin-right: vw(40px)
      padding: 0

.donut-container
  flex: 1 1 0%
  align-self: stretch

  .mobile &
    flex-direction: column
    height: 50vh

  >>> .text-container
    flex: 1 1 0%

    .mobile &
      flex: 0 0 auto

  >>> .donut
    position: relative
    flex: 1 1 0%
    height: 100%

    .mobile &
      width: 100%

.ingredients-container
  display: flex
  flex: 1 1 0%
  flex-direction: column
  align-items: center
  padding: vw(30px)

  .mobile &
    padding: 0

  >>> .title
    m-font-size(18, 21)
    margin-bottom: vh(30px)

    > b
      font-weight: 400

    .mobile &
      margin-bottom: vw(20px)

  >>> .ingredients-list
    display: flex
    flex: 1 1 0%
    flex-wrap: wrap
    overflow-y: auto
    max-height: vw(250px)

    .ingredient-item
      $margin = vw(10px)
      $percentage = percentage((1 / 3))
      $width = "calc(%s - (%s * 2))" % ($percentage $margin)
      flex: 0 0 $width
      margin: $margin
      height: vw(100px)

      .image
        flex: 0 0 vw(50px)
        margin-bottom: vh(15px)
        width: vw(50px)
        height: vw(50px)

      .label
        m-font-size(12, 20)
        m-letter-spacing(20)

  >>> .see-more-btn
    m-font-size(14, 21)
    margin-top: vh(15px)
    color: $primary-theme-color
    font-weight: 400
    cursor: pointer

    &:hover
      text-decoration: underline

.icon-close
  m-icon("cross-black", 20)
  position: absolute
  top: vw(30px)
  right: vw(20px)
  z-index: 1
  cursor: pointer

  .mobile &
    m-icon("cross-black", 25)
    margin-left: auto
</style>
<template>
  <div class="perfume-item" @click="value=!value">
    <div class="fake-checkbox-container">
      <input v-model="value" type="checkbox">
      <div class="fake-checkbox" />
    </div>
    <div class="image" :style="{backgroundImage: `url(${bestImage})`}" />
    <div class="info">
      <div class="name">{{ data.name }}</div>
      <div class="brand">{{ data.brand.name }}</div>
      <div class="type">{{ data.type }}</div>
      <families-with-icon :family="data.family" :subfamily="data.secondaryFamily" />
    </div>
  </div>
</template>

<script>
import FamiliesWithIcon from "./Legacy/FamiliesWithIcon.vue";

export default {
  components: { FamiliesWithIcon },
  props: ["data"],

  data() {
    return {
      active: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.$store.getters
          .perfumes()
          .map((p) => p.id)
          .some((id) => id == this.data.id);
      },
      set(v) {
        if (!this.active) return;
        if (v) this.$store.dispatch("addPerfume", this.data);
        else this.$store.dispatch("removePerfume", this.data);
        this.$emit("click", v);
      },
    },
    isProduction() {
      return process.env.NODE_ENV === "production";
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = Object.values(this.data.eanData).find((d) => d.isMaster);
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
  },
  watch: {
    value(v) {
      if (!this.active) return;
      if (v) this.$store.dispatch("addPerfume", this.data);
      else this.$store.dispatch("removePerfume", this.data);
    },
  },
  async mounted() {
    let perfumes = this.$store.getters.perfumes();
    this.value = perfumes.find((p) => p.id === this.data.id) !== undefined;
    this.$nextTick(() => (this.active = true));
  },
};
</script>

<style lang="stylus" scoped>
.perfume-item
  display: flex
  align-items: center
  border-bottom: 1px solid rgba(#000, 0.25)

  .fake-checkbox-container
    position: relative
    margin-right: vw(10px)
    cursor: pointer

    input[type="checkbox"]
      position: absolute
      top: 50%
      left: 50%
      margin: 0
      opacity: 0
      cursor: pointer
      transform: translate(-50%, -50%)

      &:checked
        + .fake-checkbox
          m-icon("fake-checkbox-checked", 24)

    .fake-checkbox
      m-icon("fake-checkbox-unchecked", 24)
      cursor: pointer

  .image
    flex: 0 0 vw(60px)
    margin-right: vw(10px)
    width: vw(60px)
    height: vw(60px)
    background-position: center
    background-size: contain
    background-repeat: no-repeat
    mix-blend-mode: multiply

  .info
    flex: 1 1 0%
    overflow: hidden

    .name
      m-font-size(14, 20)
      text-transform: uppercase
      font-weight: $fw-bold

    .brand
      m-font-size(14, 16)
      margin-bottom: vh(5px)

    .flags
      m-font-size(14, 20)
</style>
<template>
  <div class="perfume-card" @click="onClick">
    <families-with-icon v-if="!isTopReco" :family="data.family" :subfamily="data.secondaryFamily" />
    <div class="image" :style="{backgroundImage: `url(${bestImage})`}" />
    <div class="info">
      <v-clamp class="name" :max-lines="2">{{ data.name }}</v-clamp>
      <div class="brand">{{ data.brand.name }}</div>
      <families-with-icon v-if="isTopReco" :family="data.family" :subfamily="data.secondaryFamily" />
    </div>
    <!-- <div class="price">{{ lowestPrice ? `${$t('from')} ${lowestPrice}Kč` : '' }}</div> -->
    <a
      v-if="buyUrl"
      class="btn btn--primary"
      :href="buyUrl"
      target="_blank"
      @click.stop="onBuyClicked"
    >{{ $t('show_now') }}</a>
  </div>
</template>

<script>
import FamiliesWithIcon from "./Legacy/FamiliesWithIcon.vue";
import VClamp from "vue-clamp";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";

export default {
  components: {
    FamiliesWithIcon,
    VClamp,
  },
  props: ["data", "showRemove", "isTopReco"],
  computed: {
    bootsEans() {
      if (!this.data || !this.data.eanData) return [];
      return Object.values(this.data.eanData).filter(
        (d) => d.hostId === 13 && !!d.price && !!d.productUrl
      );
    },
    bestEan() {
      if (!this.data || !this.data.eanData) return null;
      let bestEan = this.bootsEans
        .map((a) => a)
        .sort((a, b) => a.price - b.price)[0];
      if (!bestEan) bestEan = this.bootsEans[0];
      if (!bestEan) bestEan = Object.values(this.data.eanData)[0];
      return bestEan;
    },
    bestImage() {
      return this.bestEan && this.bestEan.imageUrl
        ? this.bestEan.imageUrl
        : this.data.imageUrl;
    },
    buyUrl() {
      let url = this.bestEan ? this.bestEan.productUrl : null;
      return url
        ? url +
            "?utm_source=fragrance-finder&utm_medium=referrer&utm_campaign=fragrance-finder_buy-link"
        : null;
    },
  },
  methods: {
    onClick() {
      this.$emit("click", this.data.id);
    },
    onBuyClicked() {
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.ACTION, {
        type: "BUY",
        data: {
          id: this.data.id,
        },
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.perfume-card
  position: relative
  overflow: hidden
  background-color: #fff
  cursor: pointer

  &:hover
    .name,
    .brand
      text-decoration: underline

  .image
    flex: 0 0 vw(180px)
    margin-top: vh(20px)
    margin-right: auto
    margin-bottom: vh(20px)
    margin-left: auto
    width: vw(180px)
    height: vw(180px)
    background-position: center
    background-size: contain
    background-repeat: no-repeat

    .mobile &
      flex: 0 0 vw(120px)
      width: vw(120px)
      height: vw(120px)

  .info
    overflow: hidden
    margin-bottom: vh(20px)
    padding-right: vw(20px)
    padding-left: vw(20px)
    width: 100%

    .name
      m-font-size(16, 24)
      m-letter-spacing(10)
      margin-bottom: vh(3px)
      height: vw(48px)
      text-transform: uppercase
      font-weight: 700

    .brand
      m-ellipsis(100%)
      m-font-size(14, 16)
      margin-bottom: vh(10px)

  .btn
    display: inline-flex
    margin-bottom: vh(20px)
    margin-left: vw(20px)
    padding: vw(10px) vw(20px)
    text-transform: uppercase

  .trash-button
    m-icon("trash", 16)
    position: absolute
    top: vw(5px)
    right: vw(5px)
    cursor: pointer
</style>
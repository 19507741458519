import device from "@/utils/device";
export default {
  mounted() {
    this.setReponsiveRatio();
    window.addEventListener("resize", () => {
      this.setReponsiveRatio();
    });
  },
  data() {
    return {
      currentSize: null,
    };
  },
  methods: {
    setReponsiveRatio() {
      // Desktop xd size:  1280 x 795
      // Mobile xd size: 375 x 812

      // Desktop xd size:  1280 x 720
      // Mobile xd size: 375 x 667
      // Tablet xd size: 912 x 1366
      let ratioW = 1;
      if (device === "mobile") ratioW = 1280 / 375;
      // if(device==='tablet') ratioW = 1280 / 912

      let ratioH = 1;
      // if(device==='mobile') ratioH = 720 / 667
      let measureEl = document.getElementById("measure");
      if (device === "mobile")
        ratioH = (720 / 812) * (measureEl.clientWidth / measureEl.clientHeight);
      // if(device==='tablet') ratioH = (720 / 1366) * (measureEl.clientWidth / measureEl.clientHeight)

      // added for "extra large screens"

      if (measureEl.clientWidth >= 1440) {
        ratioH *= 0.9;
        ratioW *= 0.9;
      }
      if (measureEl.clientWidth >= 1700) {
        ratioH *= 0.8;
        ratioW *= 0.8;
      }

      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      document.documentElement.style.setProperty("--vw-rr", `${ratioW}`);
      document.documentElement.style.setProperty("--vh-rr", `${ratioH}`);

      if (device === "mobile")
        document.documentElement.style.setProperty("--font-rr", `1.4`);
      // else if (device === 'tablet'){
      //   if(measureEl.clientWidth > measureEl.clientHeight) document.documentElement.style.setProperty('--font-rr', `1.4`);
      //   else document.documentElement.style.setProperty('--font-rr', `2.4`);
      // }
      else document.documentElement.style.setProperty("--font-rr", `1`);

      this.currentSize = {
        width: measureEl.clientWidth,
        height: measureEl.clientHeight,
      };
    },
  },
  watch: {
    isMobile() {
      this.setReponsiveRatio();
    },
  },
};

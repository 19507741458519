<template>
  <div class="view perfume-search" :class="{'first-search': firstSearch}">
    <div class="left-panel" :class="{'has-right-panel': (profilePerfumes && profilePerfumes.length > 0) || isMobile}">
      <div class="top">
        <div v-if="instructionsVisible && !this.$store.state.user.firstSearch" class="results-list-header">
          <div class="results-list-header-text" v-html="$t('select_fragrances_instructions')" />
          <div class="close-icon" @click="instructionsVisible = false" />
        </div>
        <search-header v-model="str" :placeholder="$t('search_perfumes_placeholder')" />
        <profile-summary-toggle
          v-if="isMobile && profilePerfumes && profilePerfumes.length > 0"
          :value="profilePerfumes.length"
          @toggle="mobileSummaryOpen = !mobileSummaryOpen"
        />
      </div>
      <div class="results">
        <loading v-if="str && $asyncComputed.results.updating" />
        <div v-else-if="!$asyncComputed.results.success && $asyncComputed.results.exception" class="no-results">{{ $t("something_went_wrong") }}</div>
        <div v-else-if="results && results.length===0" class="no-results">{{ $t("no_perfumes_found") }}</div>
        <div v-if="bestSellers && bestSellers.length > 0" class="best-sellers">
          <div class="best-sellers__title">{{ $t('best_sellers') }}</div>
          <div class="best-sellers__list" :class="{'has-padding': bestSellers.length > 2}">
            <carousel
              :scroll-per-page="false"
              :per-page="isMobile ? 1: 2"
              :pagination-enabled="false"
              :navigation-enabled="true"
            >
              <slide v-for="perfume in bestSellers" :key="perfume.id">
                <perfume-item
                  :key="perfume.id"
                  :data="perfume"
                />
              </slide>
            </carousel>
          </div>
        </div>
        <div v-if="nonBestSellers && nonBestSellers.length > 0" class="results-list">
          <div class="results-list-content">
            <perfume-item
              v-for="perfume in results"
              :key="perfume.id"
              :data="perfume"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="(profilePerfumes && profilePerfumes.length > 0)" class="right-panel" :class="{'summary-open': mobileSummaryOpen}">
      <profile-summary
        v-if="!this.$store.state.user.firstSearch"
        mode="perfumes"
        :mobile-open="mobileSummaryOpen"
        @mobileClose="mobileSummaryOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { searchByName } from "@/services/api";
import SearchHeader from "../components/SearchHeader.vue";
import PerfumeItem from "../components/PerfumeItem.vue";
import ProfileSummary from "../components/ProfileSummary.vue";
import Loading from "../components/Loading.vue";
import ProfileSummaryToggle from "../components/ProfileSummaryToggle.vue";
import { Carousel, Slide } from "vue-carousel";
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
export default {
  components: {
    SearchHeader,
    PerfumeItem,
    Carousel,
    Slide,
    ProfileSummary,
    Loading,
    ProfileSummaryToggle,
  },
  data() {
    return {
      str: "",
      instructionsVisible: true,
      mobileSummaryOpen: false,
    };
  },
  computed: {
    firstSearch() {
      return this.$store.state.user.firstSearch;
    },
    gender() {
      return this.$store.state.user.gender;
    },
    bestSellers() {
      //return this.results?.slice(0, 8);
      return this.results
        ?.filter(({ searchBestSeller }) => !!searchBestSeller)
        .sort((a, b) => a.searchBestSeller - b.searchBestSeller)
        .slice(0, 4);
    },
    nonBestSellers() {
      console.log(this.results);
      return this.results?.filter((a) => {
        return this.bestSellers.findIndex((b) => b.id === a.id) < 0;
      });
    },
  },
  watch: {
    results(results) {
      if (!results) return;
      const topResults = [...this.bestSellers, ...this.nonBestSellers]
        .slice(0, 10)
        .map(({ id }) => id);
      this.eventTracker.emitEvent(EVENT_NAME_ENUM.SEARCH_PERFUMES, {
        type: "TEXT",
        data: {
          queryString: this.str,
        },
        topResults,
      });
    },
  },
  mounted() {
    this.instructionsVisible = true;
    this.$store.dispatch("setMode", "perfumes");
  },
  asyncComputed: {
    results() {
      return this.str !== "" ? searchByName(this.str, this.gender) : null;
    },
    profilePerfumes() {
      return this.$store.getters.perfumes();
    },
  },
};
</script>
<style lang="stylus" scoped>
$family-icon-width = vw(200px)

.perfume-search
  display: flex
  flex: 1 1 0%
  overflow: hidden

  &:not(.first-search)
    .top
      .mobile &
        position: relative

  .mobile &
    flex-direction: column

  .back-button
    display: flex
    align-items: center
    margin: vh(30px) 0 0 vw(30px)
    cursor: pointer

    .mobile &
      margin-left: vw(20px)

  .back-icon
    m-icon("arrow-left", 14)
    margin-right: vw(5px)

  .back-text
    m-font-size(14, 20)
    margin-top: 0.1em
    text-transform: uppercase
    font-weight: $fw-medium

  .top
    .title
      m-font-size(16, 20)
      margin-bottom: vh(20px)

    .debounced-input
      width: 100%

  .results
    position: relative
    flex: 1 1 0%
    overflow-y: auto

    .loading-overlay
      top: 0 !important

      .mobile &
        height: "calc(100% - %s)" % vw(80px)

    .fade-enter-active,
    .fade-leave-active
      transition: opacity 0.3s

    .fade-enter,
    .fade-leave-to
      opacity: 0

  .results-list
    padding-right: vw(40px)
    padding-left: vw(10px)

    .mobile &
      padding-right: 0
      padding-left: 0

    >>> .perfume-item
      position: relative
      padding-left: vw(25px)
      cursor: pointer

      .image
        margin-right: vw(20px)

      .info
        $padding = vw(20px)
        $info-width = "calc(100% - %s - (%s * 2) + %s)" % ($right-panel-width $family-icon-width $padding)
        flex: 0 0 $info-width
        width: $info-width

        .name
          m-font-size(21, 25)
          m-letter-spacing(50)
          margin-bottom: vh(5px)

          .mobile &
            m-font-size(12, 14)

        .brand
          margin-bottom: 0

          .mobile &
            m-font-size(12, 16)
            margin-bottom: vw(5px)

        .family-and-icon
          position: absolute
          top: 50%
          right: $right-panel-width
          width: $family-icon-width
          transform: translateY(-50%)

          .mobile &
            position: static
            width: 100%
            transform: none

  .no-results
    m-font-size(28)
    position: absolute
    top: 50%
    left: 50%
    margin-top: vw(-50px)
    text-align: center
    opacity: 0.4
    transform: translate(-50%, -50%)

  .results-list-header
    display: flex
    justify-content: space-between
    align-items: center
    padding: vh(15px) vw(40px)
    padding-right: vw(20px)
    background-color: #E5E5E5

    .mobile &
      padding: vw(10px)

    .results-list-header-text
      m-font-size(12, 16)
      m-letter-spacing(120)
      margin-right: vw(20px)

  .best-sellers
    padding: vw(30px) vw(30px) vw(20px)
    background-color: rgba(#A7A5A5, 0.1)

    .mobile &
      padding: vw(20px) vw(15px)

    .perfume-item
      padding: 0 vw(10px)
      border-bottom: none

      .mobile &
        padding: vw(10px) vw(40px)
        width: 70vw
        width: 100%

      >>> .image
        flex: 0 0 vw(70px)
        width: vw(70px)
        height: vw(70px)

  .best-sellers__title
    m-font-size(25, 30)
    margin-bottom: vw(15px)
    font-weight: bold

    .mobile &
      margin-bottom: vw(10px)

  .best-sellers__list
    display: flex
    align-items: center
    overflow-y: auto

    &.has-padding
      padding-right: vw(50px)
      padding-left: vw(50px)

    .mobile &
      padding: 0

    .desktop &
      >>> .VueCarousel-navigation-prev
        transform: translate(-150%, -50%)

      >>> .VueCarousel-navigation-next
        transform: translate(150%, -50%)

  .perfume-item
    padding: vh(15px) vw(30px)

    .mobile &
      padding: vw(10px)

  .left-panel
    display: flex
    flex: 1 1 0%
    flex-direction: column
    overflow: hidden

    &.has-right-panel
      .results-list
        >>> .perfume-item
          $padding-right = vw(20px)
          padding-right: "calc(%s + %s)" % ($family-icon-width $padding-right)

          .mobile &
            padding-right: vw(10px)

          .info
            flex: 1 1 0%
            width: 100%

      >>> .family-and-icon
        right: 0 !important

  .right-panel
    z-index: 11
    flex: 0 0 $right-panel-width
    background-color: #F2F2F2

    .mobile &
      flex: none
      width: 100%
      height: vw(80px)
      background-color: #fff
      transition: height 0.25s ease-in-out

    &.summary-open
      background-color: rgba(5, 5, 75, 0.1)

      .mobile &
        position: fixed
        bottom: 0
        left: 0
        height: 100%

        >>> .profile-summary
          overflow: hidden
          margin-top: vw(30px)
          height: "calc(100% - %s)" % vw(30px)
          border-radius: 15px 15px 0 0

        >>> .profile-summary-header
          display: flex
          align-items: flex-end
          padding-top: 0
          height: vw(120px)
          background-color: #F2F2F2

          .close-btn
            m-icon("cross-black", 25)
            top: vw(20px)
            right: vw(20px)

        >>> .profile-summary-body
          background-color: #F2F2F2

          &.show-summary
            .overlay
              z-index: 1
              transform: translateY(-100%)

        >>> .profile-summary-footer
          background-color: #F2F2F2

.close-icon
  m-icon("cross-black", 20)
  cursor: pointer
</style>
<template>
  <div class="view ingredient-search">
    <div class="left">
      <div class="top">
        <search-header v-model="str" :placeholder="$t('search_ingredients_placeholder')" />
        <profile-summary-toggle
          v-if="isMobile && profileIngredients && profileIngredients.length > 0"
          :value="profileIngredients.length"
          @toggle="mobileSummaryOpen = !mobileSummaryOpen"
        />
      </div>
      <div class="content" :class="{'state-perfumes':state==='perfumes'}">
        <div class="ingredients">
          <loading v-if="$asyncComputed.allIngredients.updating" />
          <div v-else class="ingredients-list">
            <div v-if="selection.length>0" class="disclaimer">{{ $t('best_match_for_selection') }} {{ selection.map((i)=>i.name).join(', ') }}</div>
            <div v-else-if="groupedIngredients && groupedIngredients.length===0" class="no-results">{{ $t("no_ingredients_found") }}</div>
            <!-- <div v-if="selection.length>0 && groupedIngredients && groupedIngredients.length===0" class="no-results">{{ $t("no_ingredients_found_conditioned_to_previous_selection") }}</div> -->
            <div v-for="group in groupedIngredients" :key="group.id" class="group">
              <div class="group-wrapper">
                <div class="group-label">{{ group.name }} ({{ group.ingredients.length }})</div>
                <div class="group-items main">
                  <ingredient-item
                    v-for="ing in group.ingredients.slice(0,6)"
                    :key="ing.id"
                    :data="ing"
                    :selectable="true"
                    :value="selection.indexOf(ing)>=0"
                    @select="toggleIngredient(ing)"
                  />
                </div>
                <div class="more">
                  <collapse-transition v-show="moreOpen.indexOf(group.id)>=0">
                    <div class="group-items">
                      <ingredient-item
                        v-for="ing in group.ingredients.slice(6)"
                        :key="ing.id"
                        :data="ing"
                        :selectable="true"
                        :value="selection.indexOf(ing)>=0"
                        @select="toggleIngredient(ing)"
                      />
                    </div>
                  </collapse-transition>
                  <template v-if="moreOpen.indexOf(group.id)<0 && group.ingredients.length>6">
                    <div class="more-button">
                      <span @click="moreOpen.push(group.id)">{{ $t('see_all_ings_in_this_fam') }}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <button
        v-if="isMobile && profileIngredients && profileIngredients.length >= 1"
        class="btn btn--primary"
        @click="goToRecos"
      >
        {{ $t('get_recommendations') }}
      </button> -->
    </div>
    <div v-if="profileIngredients && profileIngredients.length > 0" class="right-panel" :class="{'summary-open': mobileSummaryOpen}">
      <profile-summary
        :mode="state"
        :mobile-open="mobileSummaryOpen"
        @mobileClose="mobileSummaryOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { getAllIngredients, getRestrictedIngredients } from "@/services/api";
import { matchWords } from "@/utils/textUtils";
import ProfileSummary from "@/components/ProfileSummary";
import IngredientItem from "../components/Legacy/IngredientItem.vue";
import SearchHeader from "../components/SearchHeader.vue";
import Loading from "../components/Loading.vue";
import { CollapseTransition } from "@ivanv/vue-collapse-transition";
import ProfileSummaryToggle from "../components/ProfileSummaryToggle.vue";
import { ROUTE_NAMES } from "../router";

export default {
  components: {
    ProfileSummary,
    IngredientItem,
    SearchHeader,
    Loading,
    CollapseTransition,
    ProfileSummaryToggle,
  },
  data() {
    return {
      str: "",
      state: "ingredients",
      moreOpen: [],
      mobileSummaryOpen: false,
    };
  },
  computed: {
    selection() {
      return this.profileIngredients || [];
    },
  },
  mounted() {
    this.$store.dispatch("setMode", "ingredients");
  },
  methods: {
    toggleIngredient(ingredient) {
      if (this.selection.indexOf(ingredient) >= 0) {
        this.$store.dispatch("removeIngredient", ingredient);
        this.moreOpen = [];
      } else {
        this.$store.dispatch("addIngredient", ingredient);
        this.moreOpen = [];
      }
    },
    goToRecos() {
      this.$router.push({ name: ROUTE_NAMES.RECOMMENDATIONS });
    },
  },
  asyncComputed: {
    profileIngredients() {
      return this.$store.getters.ingredients();
    },
    allIngredients() {
      if (this.selection.length === 0) {
        return getAllIngredients();
        // .then((res) => {
        //   return res.filter((ing) => {
        //     return (
        //       (!ing.useGenericImageUrl && ing.imageUrl) ||
        //       (!ing.useGenericLightImageUrl && ing.lightImageUrl)
        //     );
        //   });
        // });
      } else {
        return getRestrictedIngredients(
          this.selection,
          this.$store.state.user.gender
        );
        // .then((res) => {
        //   return res.filter((ing) => {
        //     return (
        //       (!ing.useGenericImageUrl && ing.imageUrl) ||
        //       (!ing.useGenericLightImageUrl && ing.lightImageUrl)
        //     );
        //   });
        // });
      }
    },
    filteredIngredients() {
      return (this.allIngredients || [])
        .filter((ing) => {
          return (
            matchWords(ing.name, this.str) ||
            (ing.family && matchWords(ing.family.name, this.str))
          );
        })
        .sort((a, b) => {
          return a.name > b.name ? 1 : -1;
        });
    },
    groupedIngredients() {
      if (!this.filteredIngredients) return null;
      let aux = Object.values(
        this.filteredIngredients.reduce((res, ing) => {
          if (!res[ing.family.id])
            res[ing.family.id] = {
              id: ing.family.id,
              name: ing.family.name,
              description: ing.family.description,
              ingredients: [],
            };
          res[ing.family.id].ingredients.push(ing);
          return res;
        }, {})
      );
      aux.forEach((element) => {
        element.ingredients.sort((a, b) => {
          return -1 * (a.popularity - b.popularity);
        });
        return element;
      })
      return aux
    },
  }
}
</script>

<style lang="stylus" scoped>
.ingredient-search
  display: flex
  flex: 1 1 0%
  overflow: hidden

  .left
    display: flex
    flex: 1 1 0%
    flex-direction: column

    >>> .search-header-title
      display: none

    .top
      .mobile &
        position: relative

    .content
      position: relative
      display: flex
      flex: 1 1 0%
      flex-direction: column
      overflow: hidden
      background-color: #fff

      &.state-perfumes
        .selection
          padding-bottom: vh(20px)

          & + .ingredients
            margin-top: 0

      .selection
        padding: vh(30px) vw(30px) vh(20px)
        background-color: #fff

        .mobile &
          padding: vw(30px) vw(20px) vw(20px)

        & + .ingredients
          margin-top: vh(-20px)

        .title
          m-font-size(16, 20)
          margin-bottom: vh(20px)
          font-weight: $fw-bold

        .selected-ingredients-container
          display: flex

        .selected-ingredients-list
          display: flex
          flex: 1 1 0%
          align-items: center
          overflow-x: auto
          overflow-y: hidden
          margin: vw(-5px)

        .profile-edit-item
          margin: vw(5px)
          padding: vw(5px)
          border-radius: vw(4px)

          >>> .image
            flex: 0 0 vw(30px)
            width: vw(30px)
            height: vw(30px)

          >>> .info
            .name
              white-space: nowrap
              font-weight: $fw-regular

          >>> .trash-button
            margin-left: vw(30px)

        .next
          display: flex
          flex: 1 1 0%
          justify-content: flex-end
          cursor: pointer

        .icon-back
          m-icon("plus", 50)
          margin-left: vw(30px)
          cursor: pointer

          .mobile &
            m-icon("plus", 40)
            margin-left: vw(20px)

      .ingredients
        display: flex
        flex: 1 1 100%
        flex-direction: column
        overflow: auto
        transition: flex 0.4s ease-in-out

      .state-toggle
        flex: 0 0 auto

        .search-btn
          margin: vw(20px) auto
          width: vw(200px)

      .perfumes
        display: flex
        flex: 0 0 0%
        flex-direction: column
        overflow: auto
        transition: flex 0.4s ease-in-out

      &.state-perfumes
        .ingredients
          flex: 0 0 0%

        .perfumes
          flex: 1 1 100%

      .no-results
        m-font-size(28)
        position: absolute
        top: 50%
        left: 50%
        margin-top: vw(-50px)
        text-align: center
        opacity: 0.4
        transform: translate(-50%, -50%)

      .ingredients-list
        display: flex
        flex-direction: column
        overflow-y: auto
        padding: vw(30px)

        .disclaimer
          m-font-size(14, 18)
          margin-bottom: vw(20px)

          .mobile &
            m-font-size(16, 20)
            margin-right: vw(40px)

        .mobile &
          display: block
          padding-right: vw(20px)
          padding-bottom: vh(100px)
          padding-left: vw(20px)

        .group
          display: flex
          flex-direction: column

          .group-wrapper
            min-height: vh(200px)

          .group-label
            m-font-size(25, 30)
            margin-bottom: vh(20px)

            .mobile &
              m-font-size(20, 25)

          .group-items
            display: flex
            flex-wrap: wrap
            justify-content: flex-start

            .ingredient-item
              flex: 0 0 percentage((1 / 5))
              margin: vh(20px) 0
              cursor: pointer

              .mobile &
                $gap = 10px
                $width = percentage((1 / 3))
                flex: 0 0 "calc(%s - (%s * 2))" % ($width vw($gap))
                margin-right: vw($gap)
                margin-bottom: vw($gap * 2)
                margin-left: vw($gap)

              >>> .image
                .mobile &
                  margin-bottom: vw(10px)

          .more
            margin-bottom: vw(40px)

          .more-button
            text-align: center

            > span
              color: $primary-theme-color
              text-align: center
              text-decoration: underline
              cursor: pointer

      .loading-overlay
        top: 0 !important

      .no-results
        m-font-size(28)
        position: absolute
        top: 50%
        left: 50%
        margin-top: vw(-50px)
        text-align: center
        opacity: 0.4
        transform: translate(-50%, -50%)

  .right-panel
    z-index: 11
    flex: 0 0 $right-panel-width
    background-color: #F2F2F2

    .mobile &
      position: fixed
      bottom: 0
      flex: none
      width: 100%
      height: vw(80px)
      background-color: #fff
      transition: height 0.25s ease-in-out

    &.summary-open
      background-color: rgba(5, 5, 75, 0.1)

      .mobile &
        position: fixed
        bottom: 0
        left: 0
        height: 100%

        >>> .profile-summary
          overflow: hidden
          margin-top: vw(30px)
          height: "calc(100% - %s)" % vw(30px)
          border-radius: 15px 15px 0 0

        >>> .profile-summary-header
          display: flex
          align-items: flex-end
          padding-top: 0
          height: vw(120px)
          background-color: #F2F2F2

          .close-btn
            m-icon("cross-black", 25)
            top: vw(20px)
            right: vw(30px)

        >>> .profile-summary-body
          background-color: #F2F2F2

          &.show-summary
            .overlay
              z-index: 1
              transform: translateY(-100%)

        >>> .profile-summary-footer
          background-color: #F2F2F2

.close-icon
  m-icon("cross-black", 20)
  cursor: pointer
</style>
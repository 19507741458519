<template>
  <div class="how-it-works view">
    <loading v-if="loading" />
    <div v-else class="container">
      <div class="img img4" />
      <div class="inner">
        <div class="close-container" @click="close">
          <div class="icon-close" />
          <div class="close-background" />
        </div>
        <div class="step-1">
          <h1 v-html="$t('how_it_works')" />
          <p v-html="$t('how_it_works_p1')" />
        </div>
        <div class="step-2">
          <h2 v-html="$t('how_we_can_help_you')" />
          <p v-html="$t('how_it_works_p2')" />
        </div>
        <div class="step-3">
          <h2 v-html="$t('we_show_you_what_you_cant_see')" />
          <div class="step-wrapper">
            <div class="p-container">
              <p v-html="$t('how_it_works_p3')" />
              <p v-html="$t('how_it_works_p4')" />
            </div>
            <div class="img img1" />
          </div>
        </div>
        <div class="step-4">
          <div class="img img2" />
          <div class="p-container">
            <p v-html="$t('how_it_works_p5')" />
            <p v-html="$t('how_it_works_p6')" />
          </div>
        </div>
        <div class="step-5">
          <h2 v-html="$t('we_recommend_best_fit')" />
          <div class="step-wrapper">
            <div class="p-container">
              <p v-html="$t('how_it_works_p7')" />
              <p v-html="$t('how_it_works_p8')" />
              <p v-html="$t('how_it_works_p9')" />
            </div>
            <div class="img img3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EVENT_NAME_ENUM } from "@bestiario/puig-event-tracker/eventTypes";
import Loading from "../components/Loading.vue";
export default {
  components: { Loading },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    images() {
      return [
        require("@/assets/images/how_it_works_1.png"),
        require("@/assets/images/how_it_works_2.png"),
        require("@/assets/images/how_it_works_3.png"),
        require("@/assets/images/how_it_works_4.png"),
      ];
    },
  },
  mounted() {
    this.eventTracker.emitEvent(EVENT_NAME_ENUM.VIEW_OPENED, { type: "HELP" });
    const imgs = [];
    const loaded = [];
    this.images.forEach(() => {
      const img = new Image();
      imgs.push(img);
    });
    this.images.forEach((url, i) => {
      imgs[i].onload = () => {
        loaded.push(imgs[i]);
        if (loaded.length === imgs.length) {
          this.loading = false;
        }
      };
      imgs[i].src = url;
    });
  },
  methods: {
    close() {
      console.log("close");
      this.$emit("close");
    },
  },
};
</script>
<style lang="stylus" scoped>
.how-it-works
  position: fixed
  top: 0
  left: 0
  z-index: 3
  overflow-x: hidden
  overflow-y: auto
  width: 100%
  height: 100%
  background-color: white

  .inner
    margin-right: auto
    margin-left: auto
    padding-top: vw(60px)
    max-width: vw(1000px)

    .mobile &
      padding-top: vw(30px)
      max-width: 100%

h1
  m-font-size(40, 46)
  margin-top: 0
  margin-bottom: vw(50px)
  color: $primary-theme-color
  font-weight: 400

  .mobile &
    m-font-size(22, 26)
    margin-bottom: vw(30px)

h2
  m-font-size(30, 46)
  position: relative
  margin-top: 0
  margin-bottom: vw(30px)
  color: $primary-theme-color
  font-weight: 400

  .mobile &
    m-font-size(18, 21)

  &:after
    position: absolute
    bottom: vw(-5px)
    left: 0
    display: block
    width: vw(30px)
    height: 2px
    background-color: $primary-theme-color
    content: ""

    .mobile &
      bottom: vw(-10px)

p
  m-font-size(16, 30)

  .mobile &
    m-font-size(14, 24)

[class*="step"]
  margin-bottom: vw(100px)

  .mobile &
    margin-bottom: vw(50px)

.step-wrapper
  display: flex

  .mobile &
    display: block

.step-1
  margin-right: auto
  margin-left: auto
  max-width: vw(800px)
  text-align: center

  .mobile &
    margin-right: vw(15px)
    max-width: 60%
    text-align: left

  > p
    m-font-size(21, 41)

    .mobile &
      m-font-size(14, 24)

.step-2
  margin-left: auto
  max-width: 50%

  .mobile &
    margin-right: auto
    max-width: 90%

.step-3
  .mobile &
    margin-right: auto
    margin-left: auto
    max-width: 90%

  .step-wrapper
    .desktop &
      align-items: center

  .p-container
    flex: 1 1 0%

  .img
    flex: 0 0 vw(350px)
    margin-left: vw(50px)
    height: vw(350px)

    .mobile &
      flex: none
      margin-left: 0
      height: 50vh

.step-4
  display: flex

  .desktop &
    align-items: center

  .mobile &
    flex-direction: column-reverse
    margin-top: vw(-40px)
    margin-right: auto
    margin-left: auto
    max-width: 90%

  .p-container
    flex: 1 1 0%

  .img
    flex: 0 0 vw(350px)
    margin-right: vw(50px)
    height: vw(350px)

    .mobile &
      flex: none
      margin-right: 0
      height: 50vh

.step-5
  .mobile &
    margin-right: auto
    margin-bottom: 0
    margin-left: auto
    max-width: 90%

  .step-wrapper
    .desktop &
      align-items: center

    .mobile &
      margin-bottom: 0

  .img,
  .p-container
    flex: 1 1 0%

  .img
    flex: 0 0 vw(550px)
    margin-right: -25%
    margin-left: vw(50px)
    height: vw(400px)

    .mobile &
      flex: none
      margin-right: -10%
      margin-left: -10%
      height: 50vh
      background-size: cover

.img
  background-position: center center
  background-size: contain
  background-repeat: no-repeat

.img1
  background-image: url("~@/assets/images/how_it_works_1.png")

.img2
  background-image: url("~@/assets/images/how_it_works_2.png")

.img3
  background-image: url("~@/assets/images/how_it_works_3.png")

.img4
  position: absolute
  top: 25vh
  left: 0
  width: vw(500px)
  height: vw(500px)
  background-image: url("~@/assets/images/how_it_works_4.png")
  background-position: left center

  .mobile &
    top: 10vh
    width: vw(250px)
    height: vw(250px)

.close-container
  position: fixed
  top: vw(30px)
  right: vw(40px)
  width: vw(50px)
  height: vw(50px)
  border-radius: 2px
  background-color: rgba(#fff, 0.9)
  content: ""
  cursor: pointer

  .mobile &
    top: vw(15px)
    right: vw(15px)
    width: vw(35px)
    height: vw(35px)

.icon-close
  m-icon("cross-black", 40)
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

  .mobile &
    m-icon("close", 25)
</style>